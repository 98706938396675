<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.id" class="text-center">Add New Tax</h4>
                <h4 v-if="payload.id" class="text-center">Update Tax</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Label</b></label>
                      <input
                        type="text"
                        v-model="payload.label"
                        class="form-control form-control-sm"
                        placeholder="Label"
                      />
                      <span class="error" v-if="error && error.label">{{
                        error.label
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Description</b></label>
                      <input
                        type="text"
                        v-model="payload.description"
                        class="form-control form-control-sm"
                        placeholder="Description"
                      />
                      <span class="error" v-if="error && error.description">{{
                        error.description
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Applicable To</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.currency"
                        class="form-control form-control-sm"
                      >
                        <template v-for="(item, idx) in regionsList" :key="idx">
                          <option :value="item.id">{{item.id}}</option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>TAX Type</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.tax_type"
                        class="form-control form-control-sm"
                      >
                        <option value="PERCENTAGE">PERCENTAGE</option>
                        <option value="FIXED">FIXED</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Value</b></label>
                      <input
                        type="number"
                        v-model="payload.value"
                        class="form-control form-control-sm"
                        placeholder="Price"
                      />
                      <span class="error" v-if="error && error.value">{{
                        error.value
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Applicable On</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.applicable_on"
                        class="form-control form-control-sm"
                      >
                        <option value="INCLUSIVE">INCLUSIVE in Amount</option>
                        <option value="EXCLUSIVE">EXCLUSIVE in Amount</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Display Order</b></label>
                      <input
                        type="number"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Order"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.status"
                        class="form-control form-control-sm"
                      >
                        <option :value="true">Active</option>
                        <option :value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Add New Charge
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Charge
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Charges</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <!-- <div class="col-2">
                    <div class="form-group">
                      <label for=""><b>User Type</b></label>
                      <select name="" id="" v-model="user_type" class="form-control form-control-sm">
                        <option value="all">All</option>
                        <option value="SUPERUSER">SUPERUSER</option>
                          <option value="trainer">trainer</option>
                          <option value="student">student</option>
                      </select>
                    </div>
                  </div> -->
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + Add Charge
                  </button>
                </div>
              </div>
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>LABEL</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'tax_type' || sort_by == '-tax_type',
                    }"
                    @click="sortBy('tax_type')"
                  >
                    TAX TYPE
                    <i
                      :class="{
                        'fa-sort': sort_by != 'tax_type',
                        'fa-sort-up': sort_by == 'tax_type',
                        'fa-sort-down': sort_by == '-tax_type',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                  <th>CURRENCY</th>
                  <th>VALUE</th>
                  <th>APPLICABLE ON</th>
                  <th>ORDER</th>
                  <th
                    :class="{
                      active_sort: sort_by == 'status' || sort_by == '-status',
                    }"
                    @click="sortBy('status')"
                  >
                    STATUS
                    <i
                      :class="{
                        'fa-sort': sort_by != 'status',
                        'fa-sort-up': sort_by == 'status',
                        'fa-sort-down': sort_by == '-status',
                      }"
                      class="fa float-right"
                      style="position: relative; top: 0px"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{ item.label || '-' }}</td>
                      <td>{{ item.tax_type }}</td>
                      <td>{{ item.currency }}</td>
                      <td>{{ item.value }}</td>
                      <td>{{ item.applicable_on }}</td>
                      <td>{{ item.order }}</td>
                      <td>
                        <span class="text-success" v-if="item.status"
                          ><b>Active</b></span
                        >
                        <span class="text-warning" v-else><b>INACTIVE</b></span>
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
                <footer-table
                  :data="dataList"
                  :loading="loading_list"
                  :pageSize="pageSize"
                  @action="handleTableAction"
                  cols="6"
                ></footer-table>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "tax",
      defaultPayload: {
        label: null,
        description: null,
        currency: null,
        tax_type: null,
        value: 0,
        applicable_on: null,
        order: 1,
        data: {},
        status: true
      },
    };
  },
  mixins: [DataMixin],
  created() {
    this.getRegionsList();
  },
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.description === null ||
        that.payload.description === ""
      ) {
        this.error.description = "Description is required";
        proceed = false;
      }
      if (that.payload.price === null || that.payload.price === "") {
        this.error.price = "Price is required";
        proceed = false;
      }
      if (that.payload.credits === null || that.payload.credits === "") {
        this.error.credits = "Credits is required";
        proceed = false;
      }
      if (that.payload.validity === null || that.payload.validity === "") {
        this.error.validity = "Validity is required";
        proceed = false;
      }
      if (that.payload.order === null || that.payload.order === "") {
        this.error.order = "Order is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      return proceed;
    },
  },
};
</script>
<style lang="stylus" scoped></style>